
@use 'styles/globals/breakpoints';


.block{
    margin: 0 0 2rem 0;

    @media screen and (max-width: breakpoints.$screen-md) {
        margin: 0 2rem;
    }
}


.searchControl{
    padding-left: 4rem;
}
