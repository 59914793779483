@use "styles/globals/placeholders" as *;
@use 'styles/globals/breakpoints';


.block{
    display: flex;
    position: fixed;
    flex-direction: column;
    z-index: 9999;
    right: -36svw;
    bottom: 0;
    width: 30svw;
    left: unset;
    height: 100%;
    background-color: var(--mist);
    top: 0;
    box-shadow: var(--box-shadow);
    border-left: 1px solid #777;
    border-top: none;
    border-bottom: none;
    border-right: none;
    opacity: 0;
    transition: all 0.3s cubic-bezier(1,-0.03,.23,1);

    @media screen and (max-width: breakpoints.$screen-lg){
        width: 55svw;
        right:  -55svw !important;
    }

    @media screen and (max-width: breakpoints.$screen-sm){
        width: 95svw;
        right:  -95svw !important;
    }
        
}


.open{
    right: 0 !important;
    opacity: 1;
    transition-duration: 0.6s;
}


